<template>
<div class="quotes-container">
    <div class="quotes-top-pane flex align-center">
        <h2>Quoted Customers</h2>
    </div>
</div>
</template>

<script>
export default {

}
</script>

<style scoped>

.quotes-container
{
    position: absolute;
    top: 0; left: 90px;
    height: 100vh;
    width: calc(100% - 90px);
    background-color: #fff;
}

.quotes-top-pane
{
    height: 80px;
    border-bottom: 2px solid #f1f1f1;
    padding: 0 25px;
    box-shadow: 1px 1px 4px rgb(0, 0, 0, 0.1);
}

h2
{
    font-weight: 800;
    font-size: 26px;
}

</style>