<template>
<div class="pro-container">

    <div class="pro-top-pane flex align-center">
        <h2>Admin Profile</h2>

        <router-link :to="{ name: 'signup' }" class="btn fancy-btn signup-btn ml-auto flex center" >
            Create New Account
        </router-link>

        <button class="btn logout-btn fancy-btn ml-3" @click="openLogoutModal">
            Logout
        </button>
    </div>

    <div class="admin-details-pane" :class="{ 'd-none': !showDetails }">
        <h2 class="sub-headings"> Admin Details </h2>

        <div class="flex row justify-btw align-center">
            <p class="user-name">
                <span class="label"> Account Name: </span>
                <span class="value"> {{ user }} </span>
            </p>

            <button class="btn fancy-btn change-pass-btn" @click=" showDetails = false "> Change Password </button>
        </div>
    </div>

    <div class="admin-change-pane" :class="{ 'd-none': showDetails }">
        <h2 class="sub-headings"> Change Password </h2>
        <span @click="closeChangeBox" class="close-icon"></span>

        <div class="flex row op-row" id="oldPasswordRow">
            <label> Enter Your Old Password </label>

            <div class="form-element op-outer">
                <input
                    type="password" data-name="password" data-len="6" v-model="oldPassword"
                    id="old-password" class="form-input" placeholder="Old Password"
                    @input="handleInput" @blur="validateFields">
            </div>

            <button class="btn fancy-btn verify-btn" @click="verifyPassword"> Verify </button>
        </div>

        <div class="flex row np-row d-none" id="newPasswordRow">
            <label> Enter new password </label>

            <div class="form-element np-outer">
                <input
                    type="password" data-name="password" data-len="6" v-model="newPassword"
                    id="newPassword" class="form-input" placeholder="New Password"
                    @input="handleInput" @blur="validateFields">
            </div>

            <div class="form-element cnp-outer">
                <input
                    type="password" data-name="password" data-len="6" v-model="confirmNewPassword"
                    id="confirmNewPassword" class="form-input" placeholder="Confirm New Password"
                    @input="handleInput" @blur="validateFields">
            </div>

            <button class="btn fancy-btn change-btn" @click="changePassword"> Change </button>
        </div>

    </div>

    <div class="sms-pane flex justify-btw align-center">
        <h2> SMS Balance:
            <span>
                Rs. {{ this.$store.state.smsBalance }}
            </span>
        </h2>
        <a
            href="https://www.fast2sms.com/dashboard/wallet"
            class="btn fancy-btn recharge-btn flex center ml-auto"
            target="_blank"
            ref="recharge"
            @click="$refs.recharge.blur()"
        >
            Recharge
        </a>
        <button class="btn fancy-btn sms-btn" @click="updateSMSBalance"> Update SMS Balance </button>
    </div>

    <div class="orders-pane flex justify-btw align-center">
        <h2> Orders </h2>
        <button class="btn fancy-btn del-order-btn" @click="openOrdersModal"> Delete Old Orders </button>
    </div>

    <div class="app-pane flex justify-btw align-center">
        <h2> Appointments </h2>
        <button class="btn fancy-btn del-app-btn" @click="openAppsModal"> Delete Old Appointments </button>
    </div>

    <div class="quotes-pane flex justify-btw align-center">
        <h2> Quotations </h2>
        <button class="btn fancy-btn del-quote-btn" @click="openQuotesModal"> Delete Old Quotations </button>
    </div>

    <div class="overlay d-none" @click="closeOverlay">
        <div class="modal logout-modal d-none" @click="handleModalClick">
            <span @click="closeOverlay"></span>
            <p>Are you sure, Do you want to Logout?</p>

            <div class="btns-box flex">
                <button class="btn fancy-btn confirm-btn fixed-confirm-btn" @click="logout">YES</button>
                <button class="btn fancy-btn cancel-btn" @click="closeOverlay">NO</button>
            </div>
        </div>
        <div class="modal orders-modal d-none" @click="handleModalClick">
            <span @click="closeOverlay"></span>
            <p>Are you sure, Do you want to delete old orders?</p>

            <div class="btns-box flex">
                <button class="btn fancy-btn confirm-btn orders-confirm-btn" @click="deleteOldOrders">YES</button>
                <button class="btn fancy-btn cancel-btn" @click="closeOverlay">NO</button>
            </div>
        </div>
        <div class="modal apps-modal d-none" @click="handleModalClick">
            <span @click="closeOverlay"></span>
            <p>Are you sure, Do you want to delete old appointments?</p>

            <div class="btns-box flex">
                <button class="btn fancy-btn confirm-btn apps-confirm-btn" @click="deleteOldApps">YES</button>
                <button class="btn fancy-btn cancel-btn" @click="closeOverlay">NO</button>
            </div>
        </div>
        <div class="modal quotes-modal d-none" @click="handleModalClick">
            <span @click="closeOverlay"></span>
            <p>Are you sure, Do you want to delete old quotations?</p>

            <div class="btns-box flex">
                <button class="btn fancy-btn confirm-btn quotes-confirm-btn" @click="deleteOldQuotes">YES</button>
                <button class="btn fancy-btn cancel-btn" @click="closeOverlay">NO</button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import $ from 'jquery';
import { Admin, Orders, Appointments, Quotes } from '../../services/Service';
import { mapActions } from 'vuex'

export default {
    data() {
        return {
            showDetails: true,
            oldPassword: '',
            newPassword: '',
            confirmNewPassword: ''
        }
    },
    methods: {
        async logout() {
            this.$store.dispatch('logoutUser');
            const notification = {
                type: 'success',
                message: 'Logout successful.'
            };
            this.$store.dispatch('addNotification', notification);
            this.$router.push({ name: 'login' });
        },
        async deleteOldOrders() {
            try {
                $('.orders-confirm-btn').html(`<div class="spinner-border" role="status"></div>`);
                const response = await Orders.deleteOld();
                if( response.status === 200 )
                {
                    $('.orders-confirm-btn').html(`confirm`);
                    const notification = { type: 'success', message: response.data.message };
                    this.$store.dispatch('addNotification', notification);
                    this.closeOverlay();
                }
            } catch (error)
            {
                this.closeOverlay();
                $('.orders-confirm-btn').html(`confirm`);
                const message = error.response?.data?.message;
                const notification = { type: 'error', message }
                this.$store.dispatch('addNotification', notification);
            }
        },
        async deleteOldApps() {
            try {
                $('.apps-confirm-btn').html(`<div class="spinner-border" role="status"></div>`);
                const response = await Appointments.deleteOld();
                if( response.status === 200 )
                {
                    $('.orders-confirm-btn').html(`confirm`);
                    const notification = { type: 'success', message: response.data.message };
                    this.$store.dispatch('addNotification', notification);
                    this.closeOverlay();
                }
            } catch (error)
            {
                this.closeOverlay();
                $('.orders-confirm-btn').html(`confirm`);
                const message = error.response?.data?.message;
                const notification = { type: 'error', message }
                this.$store.dispatch('addNotification', notification);
            }
        },
        async deleteOldQuotes() {
            try {
                $('.quotes-confirm-btn').html(`<div class="spinner-border" role="status"></div>`);
                const response = await Quotes.deleteOld();
                if( response.status === 200 )
                {
                    $('.orders-confirm-btn').html(`confirm`);
                    const notification = { type: 'success', message: response.data.message };
                    this.$store.dispatch('addNotification', notification);
                    this.closeOverlay();
                }
            } catch (error)
            {
                this.closeOverlay();
                $('.orders-confirm-btn').html(`confirm`);
                const message = error.response?.data?.message;
                const notification = { type: 'error', message }
                this.$store.dispatch('addNotification', notification);
            }
        },
        openLogoutModal() {
            $('.overlay').removeClass('d-none');
            $('.logout-modal').removeClass('d-none');
        },
        openOrdersModal() {
            $('.overlay').removeClass('d-none');
            $('.orders-modal').removeClass('d-none');
        },
        openAppsModal() {
            $('.overlay').removeClass('d-none');
            $('.apps-modal').removeClass('d-none');
        },
        openQuotesModal() {
            $('.overlay').removeClass('d-none');
            $('.quotes-modal').removeClass('d-none');
        },
        handleModalClick(e) {
            e.stopPropagation();
        },
        closeOverlay() {
            $('.overlay').addClass('d-none');
            $('.modal').addClass('d-none');
        },
        closeChangeBox() {
            this.oldPassword = '';
            this.newPassword = '';
            this.confirmNewPassword = '';
            this.showDetails = true;
            $('.admin-change-pane  .error-msg').remove();
            $('.verify-btn').html(`Verify`);
            $('.change-btn').html(`Change`);
        },
        handleInput(e)
        {
            if( e.target.value === '' )
                this.addErrorMessage( e.target.id );
            else
                this.removeErrorMessage( e.target.id );
        },
        validateFields({target: el})
        {
            const { name, len } = el.dataset;
            if(el.value === '')
                this.addErrorMessage( el.id );
            else if( el.value.length < len )
                this.addErrorMessage( el.id, `${name} must have atleast ${len} digits.`);
        },
        addErrorMessage( id, msg )
        {
            $(`.${id}-error-msg`).remove();
            var contents;
            if(msg)
                contents = `<p class="error-msg ${id}-error-msg">${msg}</p>`;
            else
                contents = `<p class="error-msg ${id}-error-msg">${$(`#${id}`).data('name')} can't be empty </p>`;
            $(`#${id}`).parent().addClass('error-parent').append(contents);
        },
        removeErrorMessage(id)
        {
            $(`.${id}-error-msg`).remove();
            $(`#${id}`).parent().removeClass('error-parent');
        },
        async verifyPassword() {
            if( this.oldPassword === '' )
                this.addErrorMessage('old-password');
            else if( this.oldPassword.length < 6 )
                this.addErrorMessage('old-password','password must have atleast 6 chars.');

            if( $('#oldPasswordRow .error-msg').length ) return;
            $('.verify-btn').blur();
            $('.verify-btn').html(`<div class="spinner-border" role="status"></div>`);

            try {
                const response = await Admin.checkPassword({
                    password: this.oldPassword
                });

                if( response.status === 200 )
                {
                    $('.verify-btn').html(`verify`);
                    var notification;
                    if( response.data.match )
                    {
                        notification = {
                            type: 'success',
                            message: 'Password Verified.'
                        };

                        this.oldPassword = '';
                        $('#oldPasswordRow').addClass('d-none');
                        $('#newPasswordRow').removeClass('d-none');
                    }
                    else
                    {
                        notification = {
                            type: 'error',
                            message: 'Password not match. Try Again.'
                        };
                    }
                    this.$store.dispatch('addNotification', notification);
                }
            } catch (error)
            {
                const message = error.response?.data?.message;
                const notification = { type: 'error', message }
                $('.verify-btn').html(`verify`);

                this.$store.dispatch('addNotification', notification);
            }
        },
        async changePassword() {
            if( this.newPassword === '' )
                this.addErrorMessage('newPassword');
            else if( this.newPassword.length < 6 )
                this.addErrorMessage('newPassword','password must have atleast 6 chars.');

            if( this.confirmNewPassword === '' )
                this.addErrorMessage('newPassword');
            else if( this.confirmNewPassword.length < 6 )
                this.addErrorMessage('confirmNewPassword','password must have atleast 6 chars.');
            else if( $('#newPassword').val() !== $('#confirmNewPassword').val() )
                this.addErrorMessage('confirmNewPassword','Passwords does not match.')

            if( $('#newPasswordRow .error-msg').length ) return;
            $('.change-btn').blur();
            $('.change-btn').html(`<div class="spinner-border" role="status"></div>`);

            try {
                const response = await Admin.changePassword({
                    password: this.newPassword
                });

                if( response.status === 200 )
                {
                    $('.change-btn').html(`Change`);
                    const notification = {
                        type: 'success',
                        message: 'Password Changed successfully.'
                    };
                    this.$store.dispatch('addNotification', notification);

                    this.showDetails = true;
                    this.newPassword = '';
                    this.confirmNewPassword = '';
                    $('#oldPasswordRow').removeClass('d-none');
                    $('#newPasswordRow').addClass('d-none');
                }
            } catch (error)
            {
                const message = error.response?.data?.message;
                const notification = { type: 'error', message }
                $('.change-btn').html(`change`);

                this.$store.dispatch('addNotification', notification);
            }
        },
        async updateSMSBalance() {
            $('.sms-btn').blur();
            $('.sms-btn').html(`<div class="spinner-border" role="status"></div>`);
            const result = await this.getSMSBalance();
            var notification;
            if( result )
                notification = { type: 'success', message: 'SMS Balance Updated.' };
            else
                notification = { type: 'error', message: 'Error. Try Again.' };

            $('.sms-btn').html(`Update SMS Balance`);
            this.$store.dispatch('addNotification', notification);
        },
        ...mapActions(['getSMSBalance'])
    },
    computed: {
        user() {
            return this.$store?.state?.user?.username;
        }
    },
    watch: {
        newPassword(val) {
            if( this.confirmNewPassword === val )
                this.removeErrorMessage('confirmNewPassword');
            else if( this.confirmNewPassword !== '' )
                this.addErrorMessage('confirmNewPassword', 'Passwords doesnot match.');
        },
        confirmNewPassword(val) {
            if( this.newPassword !== val )
                this.addErrorMessage('confirmNewPassword', 'Passwords doesnot match.');
            else
                this.removeErrorMessage('confirmNewPassword');
        }
    },
    created () {
        this.getSMSBalance();
    },
}
</script>

<style scoped>

.pro-container
{
    position: absolute;
    top: 0; left: 90px;
    height: 100vh;
    width: calc(100% - 90px);
    background-color: #fff;
    overflow-y: auto;
}

.pro-top-pane
{
    height: 80px;
    border-bottom: 2px solid #f1f1f1;
    padding: 0 25px;
    box-shadow: 1px 1px 4px rgb(0, 0, 0, 0.1);
    position: -webkit-sticky;
    position: sticky;
    z-index: 2;
    top: 0;
    background-color: #fff;
}

h2
{
    font-weight: 800;
    font-size: 26px;
}

h2 span { font-size: 22px; font-family: "Poppins", serif; line-height: 24px; }

.logout-btn, .signup-btn
{
    height: 45px; width: 150px;
    border-radius: 3px;
    font-size: 16px;
    box-shadow: 1px 1px 5px rgb(0, 0, 0, 0.1);
    background-color: #000;
    color: #fff;
    font-weight: 700;
}

.signup-btn { width: 220px; }

.signup-btn::after { background-color: #00917c; }

.logout-btn::after { background-color: #E20613; }

h2.sub-headings { text-transform: capitalize; }

/* Admin Details Pane */

.admin-details-pane
{
    margin: 30px 15px;
    border-bottom: 2px solid #f1f1f1;
    padding: 30px;
    box-shadow: 0px 0px 6px rgb(0, 0, 0, 0.2);
}

.user-name
{
    margin: 12px 0;
    font-weight: 600;
}

.user-name .label
{
    font-size: 15px;
}

.user-name .value
{
    margin-left: 10px;
    font-size: 18px;
}

.change-pass-btn
{
    height: 45px;
    padding: 0 25px;
    border-radius: 3px;
    font-size: 16px;
    box-shadow: 1px 1px 5px rgb(0, 0, 0, 0.1);
    background-color: #000;
    color: #fff;
    font-weight: 700;
    text-transform: capitalize;
}

.change-pass-btn::after { background-color: #00917c; }

/* Change  */

.admin-change-pane
{
    margin: 30px 15px 30px;
    border-bottom: 2px solid #f1f1f1;
    padding: 30px;
    box-shadow: 0px 0px 6px rgb(0, 0, 0, 0.2);
    position: relative;
}

.form-input
{
    height: 50px; width: 350px;
    border: 3px solid #000;
    background-color: white;
    box-shadow: 3px 3px 4px rgb(255, 255, 255,0.15);
    outline: 0;
    padding-left: 18px;
    font-size: 16px;
    font-weight: 400;
    color: #000;
}

.form-input::placeholder { color: #000; }

.form-input:focus { border-color: #E20613; }

.op-outer, .np-outer, .cnp-outer
{
    margin-left: 30px;
}

.op-row label, .np-row label
{
    font-size: 16px;
    font-weight: 600;
    margin-top: 18px;
}

.verify-btn, .change-btn
{
    height: 50px;
    padding: 0 25px;
    border-radius: 3px;
    font-size: 16px;
    box-shadow: 1px 1px 5px rgb(0, 0, 0, 0.1);
    background-color: #000;
    color: #fff;
    font-weight: 700;
    margin-left: 30px;
    text-transform: capitalize;
}

.verify-btn::after, .change-btn::after { background-color: #00917c; }

/* Panes */

.orders-pane, .app-pane, .quotes-pane, .sms-pane
{
    margin: 30px 15px;
    border-bottom: 2px solid #f1f1f1;
    padding: 20px 30px;
    box-shadow: 0px 0px 6px rgb(0, 0, 0, 0.2);
}

.orders-pane .btn, .app-pane .btn, .quotes-pane .btn, .sms-pane .btn
{
    height: 45px;
    padding: 0 25px;
    border-radius: 3px;
    font-size: 16px;
    box-shadow: 1px 1px 5px rgb(0, 0, 0, 0.1);
    background-color: #000;
    color: #fff;
    font-weight: 700;
}

.orders-pane .btn::after, .app-pane .btn::after, .quotes-pane .btn::after, .sms-pane .btn::after
{ background-color: #E20613; }

.sms-btn { width: 215px; }

.recharge-btn { margin-right: 15px; }

/* Overlay */

.overlay
{
    background-color: rgb(0,0,0,0.5);
    height: 100vh; width: 100vw;
    position: fixed;
    top: 0; left: 0;
    z-index: 10;
}

.modal
{
    position: fixed;
    top: 50%; left: 50%;
    transform: translate(-50%,-50%);
    background-color: #fff;
    width: 350px;
    border-radius: 12px;
    padding: 60px 25px 30px;
}

.modal span, .close-icon
{
    position: absolute;
    right: 10px; top: 10px;
    cursor: pointer;
    width: 30px; height: 30px;
}

.modal span::before, .close-icon::before, .modal span::after, .close-icon::after
{
    content: '';
    position: absolute;
    width: 3px; height: 30px;
    top: 0; left: 50%;
    background-color: black;
}

.modal span::before, .close-icon::before { transform: translateX(-50%) rotate(45deg); }

.modal span::after, .close-icon::after { transform: translateX(-50%) rotate(-45deg); }

.modal span:hover, .close-icon:hover { opacity: 0.5; }

.modal p
{
    font-size: 18px;
    line-height: 1.5;
    font-weight: 500;
    text-align: center;
}

.btns-box { margin-top: 25px; }

.confirm-btn, .cancel-btn
{
    height: 40px; width: 100px;
    border-radius: 3px;
    font-size: 16px;
    padding-top: 3px;
    box-shadow: 1px 1px 5px rgb(0, 0, 0, 0.3);
    color: #fff;
    font-weight: 700;
}

.confirm-btn { background-color: #00917c; margin-left: auto; margin-right: 15px; }

.cancel-btn { background-color: #E20613; margin-right: auto; }

</style>