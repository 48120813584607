<template>
<div>
    <SideBar />
    <QuotesContainer />
</div>
</template>

<script>
import SideBar from '@/components/admin/SideBar.vue';
import QuotesContainer from '@/components/admin/QuotesContainer.vue';

export default {
    components: {
        SideBar,
        QuotesContainer
    },
}
</script>

<style scoped>

</style>